a {
  color: gray;
}

.App {
  background-color: #f2f2f7;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
}

.Title {
  display: flex;
  align-items: center;
  width: 90%;
  margin: 2.5em;
  max-width: 1150px;
}

.Title a {
  color: black;
  text-decoration: none;
}

.Logo {
  width: 3em;
  height: 3em;
  border-radius: 1.5em;
  margin-right: 0.5em;
}

.Subtitle {
  text-align: center;
  margin-bottom: 3em;
}

.Goal {
  margin-top: 0;
}

.Kakao {
  width: 17em;
  height: 5em;
  border-radius: 1.5em;
  margin: 0.5em;
}

.Naver {
  width: 17em;
  height: 5em;
  border-radius: 1.5em;
  margin: 0.5em;
}

.Hours {
  background-color: white;
  border-radius: 2em;
  padding: 2em 0em 2.5em 0em;
  width: 80%;
  padding: 2% 5%;
  margin-bottom: 1em;
  max-width: 1000px;
}

.Location {
  background-color: white;
  border-radius: 2em;
  padding: 2em 0em 2.5em 0em;
  width: 80%;
  padding: 2% 5%;
  max-width: 1000px;
}

footer {
  margin: 2.5em 0;
}